/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Environment For Call
 */
export enum Environment {
  DEVELOPMENT = 'development',
  LOCAL = 'local',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
