import {
  Entity,
  OpenSearchService,
  SimpleResponse,
} from '../api/opensearch/client';
import { Community } from '../api/opensearch/client/models/Community';
import { CommunityStatus } from '../api/opensearch/client/models/CommunityStatus';

export class CommunityService {
  private static getToken() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Invalid authorization');
    }

    return token;
  }

  public static getLiveCommunities = async (
    accountId: number,
    limit = 3,
  ): Promise<Community[]> => {
    const token = this.getToken();

    const data = await OpenSearchService.getCardsByAccountId<Community>(
      Entity.COMMUNITY,
      accountId,
      limit,
      undefined,
      token,
      CommunityStatus.ACTIVE,
    );

    return data.results;
  };

  public static getCommunitiesByAccount = async (
    accountId: number,
    limit = 300,
  ): Promise<Community[]> => {
    const token = this.getToken();

    const data = await OpenSearchService.getCardsByAccountId<Community>(
      Entity.COMMUNITY,
      accountId,
      limit,
      undefined,
      token,
    );

    return data.results;
  };

  public static getCommunitiesAggregateByAccount = async (
    accountId: number,
  ): Promise<SimpleResponse<Community>> => {
    const token = this.getToken();
    const data = await OpenSearchService.getCardsByAccountId<Community>(
      Entity.COMMUNITY,
      accountId,
      0,
      undefined,
      token,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    );

    return data;
  };
}
