/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Multiple can be specified with , (OR)
 */
export enum CharityOrganizationType_Discover {
  _501C3 = '501c3',
  GIFT_AID = 'gift_aid',
  REGISTERED_CHARITY = 'registered_charity',
}
