import { Authenticator, HashAlgorithms, KeyEncodings } from '@otplib/core';
import { createDigest, createRandomBytes } from '@otplib/plugin-crypto-js';
import { keyDecoder, keyEncoder } from '@otplib/plugin-thirty-two';
import ReCAPTCHA from 'react-google-recaptcha';
import { UserService } from '../api/v3';

export class RecaptchaService {
  /**
   * Generates a temporary one time password (TOTP) which can be passed INSTEAD of recaptcha.
   * This requires use of a shared secret between client and server.
   * We do this so that automated testing tools can bypass recaptcha in production and other environments
   * where it is not workable to just turn the whole thing off.
   * Docs for the following implementation can be found here
   * https://github.com/yeojz/otplib/blob/master/README.md#quick-start
   **/
  public static generateTotp = (key: string): string => {
    try {
      const auth = new Authenticator({
        algorithm: HashAlgorithms.SHA1,
        digits: 6,
        step: 30,
        epoch: Date.now(),
        encoding: KeyEncodings.HEX,
        window: 0,
        createRandomBytes,
        createDigest,
        keyDecoder,
        keyEncoder,
      });
      return auth.generate(key);
    } catch (e) {
      console.error('Could not generate Totp from given shared secret!');
      throw e;
    }
  };

  // returns false if recaptcha-boolean is true, signifying that user is not whitelisted and must go through recpatcha
  // returns true if recaptcha-boolean is false, signifying that user is whitelisted and can skip recpatcha
  public static isUserWhitelisted = async (): Promise<boolean> => {
    const { result: user } = await UserService.currentUser();
    return !user['recaptcha-boolean'];
  };

  // returns a reCaptcha string if user is not whitelisted
  // returns ' ' (a space) if the user is whitelisted to prevent the value from being treated as falsy in checks like `if (!reCaptcha)`, avoiding false error handling.
  public static generateRecaptcha = async (
    recaptchaCurrentRef: ReCAPTCHA | null,
  ): Promise<string> => {
    const whitelisted = await this.isUserWhitelisted();
    console.debug('Is the user whitelisted?', whitelisted);

    // return a non-empty string (' ') instead of null to avoid the return value being treated as falsy by functions that call generateRecaptcha.
    if (whitelisted) return ' ';

    const reCaptchaSharedSecret = localStorage.getItem('totpSharedSecret');
    if (reCaptchaSharedSecret) {
      return this.generateTotp(reCaptchaSharedSecret);
    }

    if (!recaptchaCurrentRef) {
      console.warn(
        'Recaptcha ref yet undefined; possibly recaptcha has not loaded yet',
      );
      throw new Error('Cannot generate reCaptcha due to undefined current ref');
    }
    recaptchaCurrentRef.reset();
    const returnVal = await recaptchaCurrentRef.executeAsync();
    // return an empty string ('') instead null to signify that reCaptcha was not correctly generated
    return returnVal || '';
  };
}
