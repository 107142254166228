import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import { general_api_response_body } from '../../api';
import { GuestUser, UserGeolocation } from '@/src/types';
import config from '@/src/config';

export class UserService {
  // the createGuestUser service in the OpenApi generated service is poiting to the wrong url, hence this custom service.
  public static createGuestUser = async (requestBody: {
    verb: string;
    email: string;
    name: string;
    newsletter?: boolean;
  }): Promise<
    general_api_response_body & {
      result?: GuestUser;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/guest',
      body: requestBody,
    });
  };

  public static toggleSubscribeToNewsletter = async (
    userId: number,
  ): Promise<
    general_api_response_body & {
      result?: [];
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: `/api/user/toggleSubscribeToNewsletter/${userId}`,
    });
  };

  public static getGeoLocation = async (): Promise<UserGeolocation> => {
    const response = await fetch(config().geolocationApiUrl);
    const data = await response.json();
    return data;
  };
}
