/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CharityLookupResponse } from '../models/CharityLookupResponse';
import type { Empty } from '../models/Empty';
import type { SimpleResponse } from '../models/SimpleResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import {
  OpenSearchCampaignResponse,
  OpenSearchDiscoverOptions,
  OpenSearchRewardsResponse,
  OpenSearchUserRewardsResponse,
} from '@/src/types';
import config from '@/src/config';
import { Entity } from '../models/Entity';
import { CampaignStatus } from '../models/CampaignStatus';
import { SortFields } from '../models/SortFields';
import { CommunityStatus } from '../models/CommunityStatus';
import { Campaign } from '../models/Campaign';
import { SortDirection } from '../models/SortDirection';

export class OpenSearchService {
  /**
   * Discovery Page Implementation
   * This is the new implementation of the discovery API.
   * @param entity Entity Type to Query for
   * @param authorization User Token (Bearer XXX)
   * @param q Query String to search against
   * @param sort Sort order (badly named; sort and filter)
   * @param zakatVerified If present, zakat verified status must match this parameter (AND)
   * @param hideCommunityCampaigns If present and true, hides the community campaigns (AND)
   * @param nearMe Latitude, Longitude, Distance in km (AND)
   * @param countries ISO3166 2 letter country codes (AND)
   * @param charityType Multiple can be specified with , (OR)
   * @param categories Categories to search for (OR); multiple categories can be combined with ,
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param aggregateCategories If true, will aggregate and return categories with counts
   * @param canChooseInCampaignCreation If true, will only show communities that can have campaigns created under them.
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getDiscover({
    entity,
    authorization,
    q,
    sort,
    zakatVerified,
    hideCommunityCampaigns,
    nearMe,
    countries,
    charityType,
    categories,
    limit,
    offset,
    aggregateCategories,
    canChooseInCampaignCreation,
  }: OpenSearchDiscoverOptions): CancelablePromise<SimpleResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/discover/{entity}',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        q,
        sort,
        zakat_verified: zakatVerified,
        hide_community_campaigns: hideCommunityCampaigns,
        near_me: nearMe,
        countries,
        charity_type: charityType,
        categories,
        limit,
        offset,
        aggregate_categories: aggregateCategories,
        can_choose_in_campaign_creation: canChooseInCampaignCreation,
      },
    });
  }
  /**
   * Discovery Page Implementation (CORS)
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsDiscover(): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/discover/{entity}',
    });
  }
  /**
   * Gets featured entities
   * Gets the featured campaigns
   * @param entity Entity Type to Query for
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param authorization User Token (Bearer XXX)
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getCardsFeatured<EntityType = Campaign>(
    entity: 'campaign' | 'organization' | 'community',
    limit?: number,
    offset?: number,
    authorization?: string,
  ): CancelablePromise<SimpleResponse<EntityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/featured',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }
  /**
   * Gets featured campaigns (CORS)
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCardsFeatured(
    entity: 'campaign' | 'organization' | 'community',
  ): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/cards/{entity}/featured',
      path: {
        entity: entity,
      },
    });
  }
  /**
   * Gets campaigns or communities by account id
   * Gets the campaigns or communities by account
   * @param entity Entity Type to Query for
   * @param id Account ID
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param authorization User Token (Bearer XXX)
   * @param campaignStatus Campaign must be in these statuses
   * @param sort
   * @param sortDirection Sort Direction, expects same order as sort field, assumes ASC if nothing present.
   * @param q Query String to search against
   * @param aggregateStatuses If true, will return status aggregation based on this query.
   * @param name If present, will only query the name against this
   * @param searchAfter Used for preventing duplications (send on subsequent paging calls with value from pagination results)
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getCardsByAccountId<EntityType = Campaign>(
    entity: Entity,
    id: number,
    limit?: number,
    offset?: number,
    authorization?: string,
    status?: CampaignStatus | CommunityStatus | string, // string is incase of multiple status joined together
    sort?: SortFields,
    sortDirection?: SortDirection,
    q?: string,
    aggregateStatuses?: boolean,
    name?: string,
    searchAfter?: string,
  ): CancelablePromise<SimpleResponse<EntityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-account-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
        ...(entity === Entity.CAMPAIGN && {
          campaign_status: status,
        }),
        ...(entity === Entity.COMMUNITY && {
          community_status: status,
        }),
        sort: sort,
        sort_direction: sortDirection,
        q: q,
        aggregate_statuses: aggregateStatuses,
        name: name,
        search_after: searchAfter,
      },
    });
  }
  /**
   * Gets campaigns by account (CORS)
   * @param entity Entity Type to Query for
   * @param id Account ID
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCardsByAccountId(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
  ): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/cards/{entity}/by-account-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
    });
  }
  /**
   * @param proxy
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsSearchManage(proxy: string): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/search/manage/{proxy+}',
      path: {
        proxy: proxy,
      },
    });
  }
  /**
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static options(): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/',
    });
  }
  /**
   * Gets campaigns or communities by account id
   * Gets the campaigns or communities by account
   * @param entity Entity Type to Query for
   * @param id Campaign, Community, or Organization ID
   * @param authorization User Token (Bearer XXX)
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getCardsById<EntityType = Campaign>(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
    authorization?: string,
  ): CancelablePromise<SimpleResponse<EntityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
  /**
   * Gets campaigns by account (CORS)
   * @param entity Entity Type to Query for
   * @param id Campaign, Community, or Organization ID
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCardsById(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
  ): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/cards/{entity}/by-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
    });
  }
  /**
   * Gets campaigns or communities by account id
   * Gets the campaigns or communities by account
   * @param entity Entity Type to Query for
   * @param authorization User Token (Bearer XXX)
   * @param idList comma separated list of ids
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getCardsByIdList<EntityType = Campaign>(
    entity: 'campaign' | 'organization' | 'community',
    authorization?: string,
    idList?: string,
  ): CancelablePromise<SimpleResponse<EntityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-id-list',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        id_list: idList,
      },
    });
  }
  /**
   * Gets campaigns by account (CORS)
   * @param entity Entity Type to Query for
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCardsByIdList(
    entity: 'campaign' | 'organization' | 'community',
  ): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/cards/{entity}/by-id-list',
      path: {
        entity: entity,
      },
    });
  }

  public static qurbaniRewards = async ({
    animalType,
    countryCode,
    minPrice,
    maxPrice,
    offset,
    limit,
    userIds,
  }: {
    animalType?: string;
    countryCode?: string;
    minPrice?: number;
    maxPrice?: number;
    offset?: number;
    limit?: number;
    userIds?: string[];
  }): Promise<OpenSearchRewardsResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;
    const searchParams = new URLSearchParams();
    // adding queries conditionally
    if (animalType) {
      searchParams.append('animal', animalType);
    }
    if (countryCode) {
      searchParams.append('country', countryCode);
    }
    if (minPrice) {
      searchParams.append('min', minPrice.toString());
    }
    if (maxPrice) {
      searchParams.append('max', maxPrice.toString());
    }
    if (offset) {
      searchParams.append('offset', offset.toString());
    }
    if (limit) {
      searchParams.append('limit', limit.toString());
    }
    if (userIds) {
      searchParams.append('user_ids', userIds.join(',')); // comma separated string
    }
    const res = await fetch(
      `${baseUrl}/rewards/search?type=qurbani&${searchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getQurbaniOrganizers = async ({
    animalType,
    countryCode,
    minPrice,
    maxPrice,
    name,
  }: {
    animalType?: string;
    countryCode?: string;
    minPrice?: number;
    maxPrice?: number;
    name?: string;
  }): Promise<OpenSearchUserRewardsResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;
    const searchParams = new URLSearchParams();
    // adding queries conditionally
    if (name) {
      searchParams.append('q', name);
    }
    if (animalType) {
      searchParams.append('animal', animalType);
    }
    if (countryCode) {
      searchParams.append('country', countryCode);
    }
    if (minPrice) {
      searchParams.append('min', minPrice.toString());
    }
    if (maxPrice) {
      searchParams.append('max', maxPrice.toString());
    }
    const res = await fetch(
      `${baseUrl}/rewards/users?type=qurbani&${searchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getOrganizationsByIds = async (
    ids: string[] | number[],
  ): Promise<OpenSearchCampaignResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;
    const queryString = `id_list=${ids.join(',')}`;

    const res = await fetch(
      `${baseUrl}/cards/organization/by-id-list?${queryString}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getOrganizationById = async (
    id: number,
  ): Promise<OpenSearchCampaignResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;

    const res = await fetch(`${baseUrl}/cards/organization/by-id/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    });
    const data = await res.json();
    return data;
  };

  public static getCampaignsByIds = async (
    ids: string[] | number[],
  ): Promise<OpenSearchCampaignResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;
    const queryString = `id_list=${ids.join(',')}`;

    const res = await fetch(
      `${baseUrl}/cards/campaigns/by-id-list?${queryString}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCampaignById = async (
    id: number,
  ): Promise<OpenSearchCampaignResponse> => {
    const baseUrl = config().lg3OpenSearchUrl;

    const res = await fetch(`${baseUrl}/cards/campaign/by-id/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    });
    const data = await res.json();
    return data;
  };
  /**
   * Gets campaigns or organizations by list id.
   * Gets the campaigns or organizations by list id
   * @param entity Entity Type to Query for
   * @param id List ID
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param authorization User Token (Bearer XXX)
   * @param randomize Return results in weighted order
   * @param seed Random seed for weighted order
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getCardsByListId<EntityType = Campaign>(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
    limit?: number,
    offset?: number,
    authorization?: string,
    randomize?: boolean,
    seed?: number,
  ): CancelablePromise<SimpleResponse<EntityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-list-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
        randomize: randomize,
        seed: seed,
      },
    });
  }
  /**
   * Gets featured campaigns (CORS)
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCardsByListId(): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/cards/{entity}/by-list-id/{id}',
    });
  }
  /**
   * Find a charity by name
   * Lookup a charity by it's name.
   * @param entity Entity Type to Query for
   * @param q Query String
   * @param country Country Code
   * @param authorization User Token (Bearer XXX)
   * @param requestBody
   * @returns CharityLookupResponse
   * @throws ApiError
   */
  public static getCharityLookup(
    entity: 'campaign' | 'organization' | 'community',
    q: string,
    country?: string,
    authorization?: string,
    requestBody?: any,
  ): CancelablePromise<CharityLookupResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/charity/lookup',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        q: q,
        country: country,
      },
      body: requestBody,
    });
  }
  /**
   * Gets campaigns by account (CORS)
   * @param entity Entity Type to Query for
   * @returns Empty 200 response
   * @throws ApiError
   */
  public static optionsCharityLookup(
    entity: 'campaign' | 'organization' | 'community',
  ): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/charity/lookup',
      path: {
        entity: entity,
      },
    });
  }
  /**
   * Returns the basic system status
   * @param requestBody
   * @returns SimpleResponse 200 response
   * @throws ApiError
   */
  public static getSystemStatus(
    requestBody?: any,
  ): CancelablePromise<SimpleResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/system/status',
      body: requestBody,
    });
  }
}
