/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Sort Direction, expects same order as sort field, assumes ASC if nothing present.
 */
export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
