/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Campaign } from './models/Campaign';
export { CampaignStatus } from './models/CampaignStatus';
export { Category } from './models/Category';
export type { CategoryAggregateResponse } from './models/CategoryAggregateResponse';
export type { CharityLookupResponse } from './models/CharityLookupResponse';
export { CharityOrganizationType_Discover } from './models/CharityOrganizationType_Discover';
export type { Empty } from './models/Empty';
export { Entity } from './models/Entity';
export { Environment } from './models/Environment';
export type { Limit } from './models/Limit';
export type { Offset } from './models/Offset';
export type { PagingResponse } from './models/PagingResponse';
export type { QueryString } from './models/QueryString';
export type { Search_After } from './models/Search_After';
export type { SimpleResponse } from './models/SimpleResponse';
export { SortDirection } from './models/SortDirection';
export { SortFields } from './models/SortFields';
export type { StatusAggregateResponse } from './models/StatusAggregateResponse';
export type { UserAuthenticationHeader } from './models/UserAuthenticationHeader';

export { OpenSearchService } from './services/DefaultService';
