/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Entity Type to Query for
 */
export enum Entity {
  CAMPAIGN = 'campaign',
  ORGANIZATION = 'organization',
  COMMUNITY = 'community',
}
